import {axiosData} from "@/store/api/axios";

const state = () => ({
  paises: null || JSON.parse(localStorage.getItem('paises')),
  tiposdocumentos: null || JSON.parse(localStorage.getItem('tiposdocumentos'))
});

const getters = {
  getPaises(state) {
    return state.paises;
  },
  getTiposDocumentos(state) {
    return state.tiposdocumentos;
  }
};

const actions = {
  async getPaisesActivos({ commit }){
    this.dispatch('ui/handleLoaderBtn', true);
    const data = await axiosData(
        true,
        'paises/activos',
        'get',
        false,
        false
    );
    if (data) this.dispatch('ui/handleLoaderBtn', false);

    if (data.status) return false;

    if (typeof data === 'number') return false;
    if(data){
    commit('SET_PAISES', data);
    data
        ? localStorage.setItem('paises', JSON.stringify(data))
        : localStorage.removeItem('paises');}
  },
  async getTodoPaises({ commit }){
    this.dispatch('ui/handleLoaderBtn', true);
    const data = await axiosData(
        true,
        'paises',
        'get',
        false,
        false
    );
    if (data) this.dispatch('ui/handleLoaderBtn', false);

    if (data.status) return false;

    if (typeof data === 'number') return false;
    if(data){
      commit('SET_PAISES', data);
      data
          ? localStorage.setItem('paises', JSON.stringify(data))
          : localStorage.removeItem('paises');}
  },
  async verWalletApple(_,codigo) {
    this.dispatch('ui/handleLoaderBtn', true);
    const response = await axiosData(
        true,
        'passkit/'+codigo,
        'get',
        null,
        'arraybuffer'
    );
    this.dispatch('ui/handleLoaderBtn', false);

    if (!response) {
      console.log('Error occurred');
      return;
    }

    const blob = new Blob([response], { type: "application/vnd.apple.pkpass" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ticket_'+codigo+'.pkpass'; // Nombre del archivo con extensión .pkpass
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  },
  async verWalletCarnetApple(_,payload) {
    this.dispatch('ui/handleLoaderBtn', true);
    const response = await axiosData(
        true,
        'passkit/carnet/'+payload.personaId+'/'+payload.temporadaId,
        'get',
        null,
        'arraybuffer'
    );
    this.dispatch('ui/handleLoaderBtn', false);

    if (!response) {
      console.log('Error occurred');
      return;
    }

    const blob = new Blob([response], { type: "application/vnd.apple.pkpass" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'carnet_'+payload.personaId+'.pkpass'; // Nombre del archivo con extensión .pkpass
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  },
  async getActosActivos(){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
    const data = await axiosData(
        true,
        'actos/activas',
        'get',
        false,
        false
    );

    if(data){
     this.dispatch('ui/handleLoaderBtn', false);
     const fiestas = data.filter(x=>x.temporada != null);
     const eventos = data.filter(x=>x.temporada == null);
     this.dispatch('fiestas/setFiestas',fiestas);
     this.dispatch('eventos/setEventos',eventos);
     return data;
    }else{
      this.dispatch('ui/handleLoaderBtn', false);
    }
  } catch (error) {
    this.dispatch('ui/handleLoaderBtn', false);
    throw new Error(`Tunay api failed, error: ${error}`);
  }
  },
  async getCompras(){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'compras/tienda/sodoma',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },

  async getComprasPorEvento(_,idevento){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'compras/entradas/sodoma/'+idevento,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getMonedasByPais(_,paisId){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'monedas/pais/'+paisId,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getTiposDocumentos(_,paisId){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tiposdocumento/pais/'+paisId,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
    },
  async getDistritos(_,ciudadId){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'distritos/ciudad/'+ciudadId,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
    },
  async getProvincias(_,paisId){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'provincias/pais/'+paisId,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getEstadoCarnetByTipoNegocioId(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'general/estadocarnets/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async traerTicketsPorCompra(_,codigo){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tickets/compra/'+codigo,
          'get',
          false,
          false
      );

      if(data && data!== 404 && data !==400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return []
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      return []
    }
  },
  async traerCodigo(_,codigo){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'general/codigo/'+codigo,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async traerCompra(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Compra');

    try {
      const data =  await axiosData(
          true,
          'compras/traer',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async creaCompra(_,payload){
    try {
      await axiosData(
          true,
          'compras/crear',
          payload,
          'post',
          false,
          false
      );

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async canjearCodigo(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'general/codigo/sodoma',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getCiudades(_,provincia){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'ciudades/provincia/'+provincia,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getTipoEventos(){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Tipo de Eventos');

    try {
      const data = await axiosData(
          true,
          'tipoeventos',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getTipoNegocios(){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'general/tiponegocios',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getTimezones(){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'general/timezones',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getEventosCategorias(){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Categorias');

    try {
      const data = await axiosData(
          true,
          'eventocategorias',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getComprasByUsuario(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'compras/usuario/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getComprasByTienda(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'compras/dashboard/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getEstadoEventsCateg(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'estadosevento/cat/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getDashboardFiestas(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'clientes/dashboard/fiestas/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getDashboardSociosCumples(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'clientes/dashboard/socios/cumples',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getDashboardFiestaAsistencia(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'clientes/dashboard/fiesta/asistencia',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getDashboardSocios(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'clientes/dashboard/socios/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getDashboardbyUser(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'clientes/dashboard/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }}
};

const mutations = {
  SET_PAISES(state, payload) {
    state.paises = payload;
    localStorage.setItem('paises', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
